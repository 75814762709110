.files-card-container {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border: 1px solid #e0e0e0; /* Borde gris claro */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
  width: 100%;
  transition: transform 0.3s ease-in-out;

  &__file {
    flex-direction: column;
    gap: 2rem;
    height: 150px;
  }

  &__merchandising {
    align-items: center;
    justify-content: space-between;
    height: 85px;
  }

  &__new-merchandising {
    align-items: center;
    justify-content: center;
    height: 85px;
    border: 1px dashed #c2c2c2;
    box-shadow: none;
    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-header-icon {
  background-color: #000; /* Fondo oscuro para el ícono */
  color: #fff; /* Color del ícono */
  padding: 8px;
  border-radius: 50%;
}

.card-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-top: 8px;
}

.card-footer {
  align-self: flex-end;
  margin-top: 8px;
  font-size: 14px;
  color: #999; /* Color gris claro para el footer */
}

.files-card-icon-container {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out; /* Añade la transición para suavizar el efecto */

  &__file {
    background-color: #212123;
  }

  &__merchandising {
    background-color: #f0f0f0;
  }
}

.files-card-icon-container:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.file-card-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 24px;
  width: 24px;
  transform: translate(-50%, -50%); /* Mueve el elemento para centrarlo completamente */
  color: white;
}

.file-card-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.file-edit-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* -------------------------------------------------------------------------- */
/*                                MERCHANDISING                               */
/* -------------------------------------------------------------------------- */

.merchandising-title-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.new-merchandising {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #757575;
}

.new-merchandising-title {
  font-size: 1rem;
  font-weight: 700;
}

/* -------------------------------------------------------------------------- */
/*                               TIPO DE ARCHIVO                              */
/* -------------------------------------------------------------------------- */
.two-options-select-wrapper {
  background-color: #7a1c1c; // tono rojizo apagado para el fondo del contenedor
  border-radius: 0.4rem;
  display: grid;
  width: 130px;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #944242; // borde suave, intermedio entre los dos tonos
}

.justify-center {
  justify-content: center;
}

.padding-1 {
  padding: 0 0.25rem;
}

.optionselected {
  background-color: #d32f2f !important; // rojo más vivo y brillante para la opción seleccionada
  border: 1px solid #d32f2f !important; // borde del mismo color que el fondo
}

.option-select {
  text-transform: capitalize;
  background-color: transparent; // rojo apagado para las opciones no seleccionadas
  border-radius: 0.4rem;
  height: 100%;
  width: 100%;
  padding: 0.15rem 0.5rem;
  font-size: 14px;
  outline: none !important;
  border: none;
  color: #ffffff;
  &:hover {
    background-color: #b71c1c; // color intermedio al hacer hover
    color: #ffffff;
  }
}

.file-upload-wrapper {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

#file {
  display: none; // Ocultamos el input file original
}

.file-upload-label {
  background-color: #757575; // Color neutro, más claro que el de confirmar
  color: #ffffff;
  padding: 0.4rem 0.8rem; // Tamaño más pequeño y contenido ajustado
  font-size: 13px; // Reducción en el tamaño de la fuente
  border-radius: 0.2rem;
  cursor: pointer;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-right: 1rem; // Añadir espacio entre el botón y el texto del archivo
}

.file-upload-label:hover {
  background-color: #616161; // Oscurecer ligeramente en hover
}

.file-upload-info {
  font-size: 13px;
  color: #666666; // Color más suave para el texto
  font-style: italic;
}

.file-upload-info.active {
  color: #333333; // Cambiar el color del texto cuando se seleccione un archivo
  font-style: normal;
}
