.header {
  position: sticky;
  align-items: center;
  top: 0;
  z-index: 100;
  background-color: #c4c4c4;
}

.categories-cards {
  display: flex;
  margin: 2rem;
  // flex-wrap: wrap;
  // justify-content: center;
  // align-items: center;
  // margin: 0 auto;
  // width: 100%;
  // max-width: 1900px;
  // padding: 0 1rem;
}
