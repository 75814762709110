.social-header {
  width: 100%;
  height: 380px;
  object-fit: cover;
  max-height: 380px;
}

.social-profile-img {
  height: 120px;
  border: 3px solid $foreground-color;
  position: absolute;
  left: 50%;
  z-index: 1;
  transform: translateX(-50%);
  top: -75px;
}

.social-icons {
  ul {
    margin-bottom: 0;
  }

  li {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    a {
      color: $secondary-color;
      font-size: 1.2rem;

      &:hover,
      &:active {
        color: rgba($secondary-color, 0.7);
      }
    }
  }
}

.post-icon {
  i {
    font-size: 1.1rem;
    vertical-align: middle;
  }

  span {
    display: inline-block;
    padding-top: 3px;
    vertical-align: middle;
  }
}

.comment-likes {
  text-align: right;
  flex-basis: 90px;
  flex-shrink: 0;
}

.comment-container {
  input {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding-left: 20px;
  }
}

.social-image-row {
  [class*='col-'] {
    margin-bottom: 1rem;
  }

  [class*='col-']:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }

  [class*='col-']:nth-child(odd) {
    padding-right: 0.5rem;
  }

  [class*='col-']:nth-child(even) {
    padding-left: 0.5rem;
  }
}

.gallery-page.row {
  margin-left: -5px;
  margin-right: -5px;
  [class*='col-'] {
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.col-left {
  padding-right: 20px;
  @include respond-below(md) {
    padding-right: 15px;
  }
}

.col-right {
  padding-left: 20px;
  @include respond-below(md) {
    padding-left: 15px;
  }
}
