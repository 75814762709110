.app-layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 160px 1fr 1fr; /* Ajusta las proporciones */
  grid-template-rows: 60% 40%;

  grid-template-areas:
    'menu info viewer' /* Cambia el nombre de la primera columna a "combined" */
    'menu info graphics';
}

.combined-column {
  grid-column-start: 1;
  grid-column-end: 2;
}
.contenido-layout {
  grid-area: contenido;
  width: 100%;
  height: 100%;
  position: relative;
}
.viewer-layout {
  grid-area: viewer;
  width: 100%;
  height: 100%;
  position: relative;
}
.menu-layout {
  grid-area: menu;
  width: 100%;
  height: 100%;
  position: relative;
}
.info-layout {
  grid-area: info;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.graphics-layout {
  grid-area: graphics;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.parent {
  width: 100%;
  height: 100%;
  display: grid;

  grid-template-columns: auto 45% 45%;
  grid-template-rows: 60% 40%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.menu {
  grid-area: 1 / 1 / 3 / 2;
}
.contenido {
  grid-area: 1 / 2 / 2 / 3;
}
.viewer {
  grid-area: 1 / 3 / 2 / 4;
}
