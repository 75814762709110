.cell {
  text-overflow: ellipsis;
  max-width: 10rem;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

.cell-clusters {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}
