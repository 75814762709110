.custom-select-container {
  padding: 0 1rem;
  width: 100%;
  position: relative;
}

.arrow-icon {
  user-select: none;
  position: absolute;
  right: 7px;
  top: calc(50% - 0.5em);
  width: 1em;
  height: 1em;
}

.custom-select-container label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-size: 16px;
}

.custom-dropdown {
  width: 100%;
  padding: 8px 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: none; /* Desactivar transiciones para custom-dropdown */
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 10;
  padding: 0 1rem !important;
}

.custom-dropdown-menu li {
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  border: 1px solid #ccc;
  padding: 8px 10px;
  cursor: pointer;
  list-style-type: none;
}

.custom-dropdown-menu li:hover {
  background-color: #f0f0f0;
}
