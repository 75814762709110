.point-item {
    background: rgba(255, 0, 8, 0.7);
    box-shadow: 0 0 0 0 rgba(236, 0, 9, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-radius: 50%;
    margin: 10px;
    height: 25px;
    width: 25px;
    transform: scale(1);
    animation: pulse 2s infinite;
    position: absolute;
    z-index: 20;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &--dark {
      background: darken(rgb(40, 66, 149), 12%);
    }
  

    &--disabled {
      cursor: auto;
      opacity: 0.5;
      animation: unset;
    }

    &--green {
      background: rgba(5, 187, 5, 0.9);
      box-shadow: 0 0 0 0 rgba(5, 187, 5, 0.7);
      height: 35px;
      width: 35px;
      animation: pulseGreen 2s infinite;
    }
    
    &--selected {
      background: rgba(5, 187, 5, 0.9);
      // background: rgba(4, 0, 255, 0.7);
      // box-shadow: 0 0 0 0 rgba(3, 0, 163, 0.4);
      // height: 35px;
      // width: 35px;
      transform: scale(1.7) !important;
      // animation: pulseSelected 2s infinite;
    }

    &--aside-nav {
      background: #fff;
      height: 11px;
      width: 11px;
      box-shadow: 0 0 0 0 rgba(#fff, 0.3);
    }
  
    &--no-animation {
      animation: none;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(236, 0, 9, 0.4);    
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }

  @keyframes pulseGreen {
    0% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(0, 128, 0, 0.4);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }

  @keyframes pulseSelected {
    0% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(3, 0, 163, 0.4);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
  
    100% {
      transform: scale(0.97);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }


  
  