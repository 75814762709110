.viewer-button {
  color: #f4f4f4;
  background-color: rgba(34, 34, 34, 0.94);
  box-shadow: 1px 3px 10px 0 rgba(0, 0, 0, 0.5);
  padding: 0.5rem;
  border-radius: 5px;
  outline: none;
}

.custom-panel-mui > div {
  padding: 0.85rem;
}

.tabs-mercado.Mui-selected {
  color: #d32f2f !important;
}
