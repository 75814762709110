.display-sb-c {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex {
  display: flex;
}

.jc-sb {
  justify-content: space-between;
}

.jc-fe {
  justify-content: flex-end;
}

.ai-fs {
  align-items: flex-start;
}

.ai-c {
  align-items: center;
}

.ai-fe {
  align-items: flex-end;
}

.fd-c {
  flex-direction: column;
}

.display-c-c {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.w-100 {
  width: 100%;
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.ta-r {
  text-align: right;
}

.fs-1 {
  font-size: 1rem;
}

.fs-1-3 {
  font-size: 1.3rem;
}

.fw-b {
  font-weight: bold;
}

.c-red {
  color: #ab280b;
}

.us-none {
  user-select: none;
}

.d-grid {
  display: grid;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-col1-2 {
  grid-template-columns: 1fr 2fr;
}

.outline-none {
  outline: none;
}

.g-1 {
  gap: 1rem;
}
