.breadcrumb-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.fs-12 {
  font-size: 12px;
}

.not-more-subcategories {
  padding-left: 16px;
  padding-top: 24px;
}
