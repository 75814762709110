/* Estilos del scrollbar */

/* Estilo del thumb (barra del scrollbar) */
.container-cards-partidaproject::-webkit-scrollbar-thumb {
  background-color: #c4c4c4; /* Cambia el color según tus preferencias */
  border-radius: 5px;
}

/* Estilo del track (área donde se mueve el thumb) */
.container-cards-partidaproject::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Cambia el color según tus preferencias */
  border-radius: 5px;
}
