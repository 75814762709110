.navbarV {
  padding-top: 1rem;
  background-color: #333;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.navbarV a {
  color: white;
  text-decoration: none;
  padding: 10px;
  width: 100%;
  /* text-align: center; */
  transition: background-color 0.3s;
  font-size: 1rem;
}

.navbarV a:hover {
  background-color: #555;
  color: rgb(255, 255, 255);
}
.navbarV a.active {
  color: rgb(221, 46, 64);
}
