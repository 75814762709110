.custom-loader-table {
    width:6px;
    height:6px;
    border-radius: 50%;
    clip-path: inset(-22.5px);
    color: #FF3A3A;
    box-shadow: -30px 7.5px,-60px 7.5px,-60px 7.5px;
    transform: translateY(-7.5px);
    animation: d9 1s infinite linear;
  }
  
  @keyframes d9{ 
    16.67% {box-shadow:-30px 7.5px,-30px 7.5px,9.5px 7.5px}
    33.33% {box-shadow:-30px 7.5px,  0px 7.5px,9.5px 7.5px}
    40%,60%{box-shadow:-9.5px 7.5px,  0px 7.5px,9.5px 7.5px}
    66.67% {box-shadow:-9.5px 7.5px,  0px 7.5px,30px 7.5px}
    83.33% {box-shadow:-9.5px 7.5px, 30px 7.5px,30px 7.5px}
    100%   {box-shadow: 30px 7.5px, 30px 7.5px,30px 7.5px}
  }