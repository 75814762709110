.video-js .vjs-big-play-button {
  background: $input-background;
  height: 1.2em;
  border-radius: 0.75em;
  line-height: initial;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.5em;
  width: 2.5em;
  border: 0.06666em solid $input-background;

  .vjs-icon-placeholder {
    color: $theme-color-1;
  }
}

.vjs-poster,
.vjs-tech {
  border-radius: $border-radius;
  background-color: transparent !important;
}

.vjs-tech {
  background: initial;
  object-fit: cover;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
  background-color: darken($input-background, 5%);
  border-color: darken($input-background, 5%);
}

.vjs-control {
  text-shadow: initial !important;
  outline: initial !important;
}

.video-js .vjs-control-bar {
  background: initial;
  margin: 1.75rem;
  width: calc(100% - 3.5rem);

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    margin-right: 0.5em;
    background: $input-background;
    color: $theme-color-1;
    border-radius: 15px;
    @include depth(2);
  }

  .vjs-progress-control.vjs-control {
    background: $input-background;
    border-radius: 15px;
    margin-right: 0.5em;
    @include depth(2);
  }

  .vjs-mute-control.vjs-control {
    box-shadow: initial;
  }

  .vjs-progress-holder {
    height: 3px;
    font-size: 1.6em !important;
  }

  .vjs-load-progress,
  .vjs-load-progress div {
    background-color: rgba($theme-color-1, 0.2);
  }

  .vjs-play-progress:before {
    font-size: 0.55em;
    top: -0.2em;
  }

  .vjs-progress-holder {
    margin: 0 17px;
  }

  .vjs-slider {
    text-shadow: initial !important;
    outline: initial !important;
    background-color: darken($separator-color-light, 10%);
  }

  .vjs-play-progress {
    background: $theme-color-1;
  }

  .vjs-play-progress:before {
    color: $theme-color-1;
  }

  .vjs-volume-horizontal {
    margin-left: -1.5em;
    width: 4em;
  }

  .vjs-volume-panel .vjs-volume-level {
    background: $theme-color-1;
  }
}

.video-js.audio {
  background: initial;

  .vjs-big-play-button {
    display: none;
  }

  .vjs-control-bar {
    display: flex;
  }

  .vjs-fullscreen-control {
    display: none;
  }

  .vjs-control-bar {
    margin-bottom: 0;
  }

  .vjs-control.vjs-button,
  .vjs-remaining-time,
  .vjs-volume-panel {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-progress-control.vjs-control {
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.15), 0 0px 1px rgba(0, 0, 0, 0.2);
  }

  .vjs-mute-control {
    box-shadow: initial !important;
  }

  .vjs-loading-spinner {
    display: none !important;
  }
}

.video-js.side-bar-video {
  width: 100%;
  height: 280px;
  background-color: $background-color;
  overflow: hidden;

  video {
    object-fit: cover;
  }

  .vjs-poster {
    background-size: cover;
  }
}

.video-js.video-content {
  width: 100%;
  height: 400px;
  background-color: $background-color;
  overflow: hidden;
  max-height: unset;

  video {
    object-fit: cover;
  }

  .vjs-poster {
    background-size: cover;
  }
}
.rounded {
  .vjs-poster,
  .vjs-tech {
    border-radius: $border-radius-rounded;
  }
}
