.custom-button {
  display: inline-block;
  padding: 0.2rem 1rem;
  font-weight: 600;
  text-align: center;
  color: white;
  background-color: rgb(68, 68, 68);
  text-transform: uppercase;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:disabled {
    background-color: rgb(155, 155, 155);
    cursor: not-allowed;

    &:hover {
      background-color: rgb(155, 155, 155);
    }
  }

  &:hover {
    background-color: rgb(85, 85, 85);
  }

  &--secondary {
    background-color: white;
    border: 1px solid rgb(85, 85, 85);
    color: rgb(85, 85, 85);

    &:hover {
      background-color: rgb(240, 240, 240);
      // color: white;
    }
  }

  &--success {
    border: 1px solid green;
    background-color: transparent;
    transition: all 0.2s ease;

    &:hover {
      background-color: green;
      border-color: white;

      & svg {
        fill: white;
      }
    }
  }
}
