.image-viewer-container {
  // position: relative;
  // top: 3rem;
  // left: 3rem;
  width: 300px;
  height: 300px;
  border-radius: 0.2rem;
  z-index: 502;
  background-color: #404040;
  display: flex;
  flex-direction: column;
  // cursor: grabbing;
  // border-radius: 0.5rem;
  align-items: center;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.75);
}

.image-viewer {
  &__close {
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
    cursor: pointer;
    color: white;
    font-weight: bold;
    user-select: none;
    z-index: 600;
  }

  &__img {
    width: 100%; /* Ancho de la imagen */
    height: 100%; /* Altura de la imagen */
    padding: 1.2rem 1.2rem 1.5rem;
    object-fit: cover; /* Ajustar la imagen al contenedor */
    border-radius: 0.5rem;
    // background-color: #f7f7f7;
    background-color: #404040;
  }

  &__title {
    color: white;
    // margin: 0.7rem 0 0.2rem;
    margin-top: 0.2rem;
    font-size: 1.2rem;
  }

  &__swiper-container {
    display: flex;
    color: white;
    gap: 1rem;
    // margin-bottom: 0.8rem;
    margin-top: 1.5rem;
    user-select: none;
  }

  &__swiper {
    color: white;
    margin: 0;
  }

  &__pdf {
    width: 300px;
    height: 80%;
    overflow: auto;
    // & > div {
    //   width: 300px;
    //   height: 255px;
    // }
    & canvas {
      width: 100% !important;
      height: 100% !important;
      max-height: max-content !important;
    }

    & .annotationLayer {
      display: none !important;
    }

    & .textLayer {
      display: none !important;
    }
    // border-radius: 0.5rem;
    // background-color: #404040;
  }

  &__swiper-arrow {
    cursor: pointer;
    font-weight: 900;
    font-size: 1.2rem;
    margin: 0;
  }
}

.wrapper-fullScreen {
  margin-left: -15px;
  margin-right: -15px;
  // width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba(0, 0, 0, 0.315);
  backdrop-filter: blur(0.1rem);
  z-index: 800;
}

.pdf-object {
  height: 100%;
  width: 100%;

  &--big-modal {
    height: 85%;
    width: 85%;
    margin-top: 3rem;
  }
}

.iframe-pdf::-webkit-scrollbar {
  width: 10px;
}

.iframe-pdf::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.iframe-pdf::-webkit-scrollbar-thumb {
  background-color: red;
  border-radius: 10px;
}

.mySwiper {
  position: absolute;
  top: 10%;
  left: 15%;
  width: 70%;
  height: 80%;
  z-index: 502;
  background-color: #323639;
  // border-radius: 0.5rem;
  // padding: 1.2rem 1.2rem 1.5rem;
  // margin: 0.7rem 0 0.2rem;
  // margin-top: 0.2rem;
  // font-size: 1.2rem;
  // color: white;
  // user-select: none;
}

.modal-img-title {
  position: absolute;
  top: 1rem;
  color: white;
  z-index: 100;
}

.swiper-pagination-bullet-active {
  color: $red-color;
  background-color: $red-color;
}

.swiper-button-prev,
.swiper-button-next {
  color: $red-color;
}

.swiper-pagination-fraction {
  color: white;
}

.circular-progress-custom {
  // color : $red-color !important;
}
