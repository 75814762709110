.forge-viewer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    // min-height: 200px;
    width: 100%;
    // height: 800px;
}

#label {
    display:none;
    position:fixed;
    z-index:2;
    border: 2px solid #ccc;
    background-color: #404040;
    border-radius: 25px;
    padding: 10px;
    color: white;
}
#label img { width:200px; }

.adsk-icon-markup {
    background-image: url("./../../../img/dummy/icons8-marcador-50.png");
    background-size: contain;
    background-position: center;
}

#toolbar-markup.active {
    .adsk-icon-markup {
        background-image: url("./../../../img/dummy/icons8-marcador-50-red.png");
    }
}

.adsk-button{
    &:hover {
        color: $red-color !important;
        border-color: $red-color !important;
    }
}

.custom-cursor{
    cursor: pointer;
}

.loader {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    visibility: visible;
    text-align: center;
    /* modified */
    display: inline-block;
    width: 233px;
    height: 200px;
    transform: rotate(45deg);
    transform-origin: 355px 260px;
}

// canvas[data-uuid="custom-scene"] {
//     pointer-events: none;
//   }
  
//   canvas[data-uuid="custom-scene"] + div > canvas {
//     pointer-events: auto;
//   }
