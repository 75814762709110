@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,400i,600,700');
@import './_01_mixins.scss';
@import './_02_variables';
@import './_03_core.scss';
@import './_04_plugins.scss';
@import './_05_views.scss';
/** your custom css code **/
@import './layout/index.scss';
@import './components/index.scss';
/* 
html {
    background-color: $theme-color-1 !important;
}
*/
