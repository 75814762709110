.button-add {
  width: 100%;
  background-color: rgb(239, 83, 80);
  color: rgb(10, 10, 10);
  transition:
    background-color 0.3s,
    color 0.3s; /* Agregamos transición para suavizar el cambio */
}

.button-add:hover {
  background-color: rgb(240, 40, 13);
  color: #ffffff; /* Blanco */
}

.btn-disabled {
  display: none !important;
  // background-color: yellowgreen;
}

.css-honmcm-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}

.css-sayu3r > .MuiGrid-item {
  padding-top: 0px !important;
}
