.select-section-row {
  height: 80%;
}

.select-section-col {
  padding: 0;
}

.select-section-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.select-section-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.select-section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(100, 100, 100, 0.4); /* Fondo oscuro inicialmente */
  // display: flex;
  // align-items: center;
  // justify-content: center;
  opacity: 1;
  transition: background-color 0.3s ease;
}

.select-section-text {
  color: #fff; /* Color del texto */
  font-size: 30px; /* Tamaño del texto */
  font-weight: 600;
  text-shadow: 0 1px 2px #1d1d1d;
  letter-spacing: 2px;
  user-select: none;
  font-family: 'SanaSans', sans-serif;
  margin-top: 10%;
  line-height: 1.5;
  text-align: center;
}

.select-section-image-container:hover .select-section-overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Hacer más oscuro al pasar el ratón */
  cursor: pointer;
}

.select-section-image-container:hover .select-section-image {
  transform: scale(1.1); /* Zoom al pasar el ratón */
}

.loader-projects-login {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #e0001a;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// Spinner para los proyectos

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px; /* Ajusta el tamaño a la mitad */
  height: 40px; /* Ajusta el tamaño a la mitad */
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px; /* Ajusta el tamaño a la mitad */
  height: 32px; /* Ajusta el tamaño a la mitad */
  margin: 4px; /* Ajusta el tamaño a la mitad */
  border: 4px solid #000000; /* Ajusta el tamaño a la mitad */
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.225s; /* Ajusta el tamaño a la mitad */
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.15s; /* Ajusta el tamaño a la mitad */
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.075s; /* Ajusta el tamaño a la mitad */
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
