/* -------------------------------------------------------------------------- */
/*                                  MAIN INFO                                 */
/* -------------------------------------------------------------------------- */
.title-section-arte-final {
  padding: 1rem;
  background-color: #cecece;
  border-radius: 0.5rem;
  text-transform: capitalize;
  font-weight: 700;
}

.title-text-section-arte-final {
  font-weight: 600;
}
/* -------------------------------------------------------------------------- */
/*                           FORMAT/IMPRIMIBLES DATA                          */
/* -------------------------------------------------------------------------- */
.imprimibles-section {
  margin-top: 2rem;
}

.imprimibles-container {
  display: flex;
  margin-top: 1rem;
  gap: 2rem;
}

.title-container {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 0.3rem;
  font-size: 1.4rem;
  align-items: center;
}

.item-arte-final-container {
  justify-content: space-between;
  border: 1px solid #eeeeee;
  border-radius: 0.5rem;
  background-color: white;
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.65rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  &--format-title {
    flex-direction: row;
    align-items: center;
    background-color: white; /* Color de fondo inicial */
    position: relative;
    overflow: hidden;
    padding: 0rem 1rem;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.01);
      cursor: pointer;
    }
  }

  &--format-title-not-active {
    flex-direction: row;
    align-items: center;
    background-color: rgb(230, 230, 230); /* Color de fondo inicial */
    border-color: rgb(230, 230, 230);
    position: relative;
    overflow: hidden;
    padding: 0rem 1rem;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.01);
      cursor: pointer;
    }
  }

  &--not-active {
    background-color: #e6e6e6 !important;
  }

  &--format {
    flex: 0 0 calc(25% - 2rem);
    padding: 0;

    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      cursor: pointer;
    }
  }

  &--pointer {
    cursor: pointer;
  }

  & p {
    margin: 0;
  }
}

.label-is-compuesto {
  color: green;
  border: 1px solid green;
  border-radius: 1rem;
  margin: 0;
  background-color: rgba(0, 128, 0, 0.1);
  padding: 0 0.4rem;
  font-size: 0.6rem;
}

.item-arte-final-header {
  font-size: 1rem;
  font-weight: 700;
}

.item-arte-final-price {
  padding: 0.3rem 0.6rem;
  border: 2px solid #d32f2f;
  width: fit-content;
  border-radius: 0.25rem;
  font-weight: 700;
  color: #d32f2f;
  text-transform: capitalize;
}

.format-container {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.format-container--column {
  flex-direction: column;
  gap: 5em;
}

.format-container--grid {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.add-format-provider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c2c2c2;
  color: #757575;
  height: 100%;
}

/* -------------------------------------------------------------------------- */
/*                              ARTE FINAL IMAGEN                             */
/* -------------------------------------------------------------------------- */
.image-arte-final-container {
  object-fit: contain;
  background-color: transparent;
  cursor: pointer;
  background-color: transparent;
  object-position: top;
}

/* -------------------------------------------------------------------------- */
/*                           ARTE FINAL DESCARGABLE                           */
/* -------------------------------------------------------------------------- */

.buttons-pdf-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.buttons-pdf-confirm-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.button-pdf-download-container {
  position: relative;
  display: flex;
  gap: 0.3rem;
  align-items: center;
  outline: none;
  border: none;
  background: none;
  overflow-x: hidden;
  max-width: 100%;
}

.button-text-pdf {
  white-space: nowrap; /* Evita que el texto se divida en múltiples líneas */
  overflow: hidden; /* Oculta el contenido que excede el contenedor */
  text-overflow: ellipsis;
}

.button-not-pdf {
  color: #d32f2f;
  font-style: oblique;
}

.button-pdf-confirm {
  color: green;
}

.button-pdf-upload-imprimibles {
  padding: 0.2rem 0.5rem;
  display: flex;
  align-items: center;
}

.icon-check-pdf-upload-imprimibles {
  margin: 0;
  padding: 0;
  color: green;
  font-size: 18px !important;
}

/* -------------------------------------------------------------------------- */
/*                            MAIN INFO ARTE FINAL                            */
/* -------------------------------------------------------------------------- */
.one-arte-final-container {
  display: flex;
  justify-content: space-between;
  gap: 3rem;
}

.main-info-container {
  width: 1000px;
  max-width: 65%;
  font-size: 1.2rem;

  & p,
  h2,
  h3 {
    font-size: 0.9rem;
  }
}

.title-buttons-main-info-container {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.delete-icon-main-info {
  cursor: pointer;
  color: #d32f2f;
}

.edit-icon-main-info {
  cursor: pointer;
  margin-left: 2rem;
}

.main-info-section-container {
  display: flex;
  gap: 2rem;
  margin: 0.5rem 0;
}

.main-info-image-container {
  flex: 1;
  margin-left: 20px;
  position: relative;
}

.button-edit-arte-final {
  top: 0.5rem;
  right: 0.5rem;
  position: absolute;
  // border: 1px solid white;
  box-shadow: 0px 0px 9px 0px rgba(126, 126, 126, 0.5);
}
