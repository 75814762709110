.button-images-arte-final {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 9rem;
}

.buttons-images-arte-final-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.custom-button-formato {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
