.create-arte-final-container-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 20px;
  justify-items: center;
  height: 90%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.95);

  &--flex {
    display: flex;
    flex-direction: column;
  }
}

.category-select-container {
  width: 35%;
  min-height: 500px;
  min-width: 500px;
  width: 500px;
  max-height: 100%;
  overflow: auto;
  margin-right: 1rem;
}

.inputs-edit-modal-row-container {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
}

.create-center-container-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 20px;
  justify-items: center;
  height: 68%;
  background-color: rgba(255, 255, 255, 0.95);

  &--flex {
    display: flex;
    flex-direction: column;
  }
}

.create-cluster-container-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 20px;
  justify-items: center;
  max-height: 90vh;
  overflow: auto;
  // height: 43%;
  background-color: rgba(255, 255, 255, 0.95);

  &--flex {
    display: flex;
    flex-direction: column;
  }
}

.button-group-tiendas {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.center-row-md {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
}

.cluster-row-md {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  width: 100%;
  justify-content: space-around;
}

.center-form-title {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.center-form {
  overflow: auto;
  height: 100%;
  flex: 2;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}

.button-confirm-color {
  background-color: #3c4145;
}

.spinner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
