.custom-button-outlined {
  border: 1px solid #050505 !important;
  background-color: #fff !important;
  color: #000 !important;
  &:hover {
    background-color: #f8f8f8 !important;
    // color: #fff !important;
  }
}

.button-upload-file {
  clip: rec(0, 0, 0, 0);
  clip-path: inset(50%);
  height: 1;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1;
}

.button-upload-file--imprimibles {
  cursor: pointer;
  position: absolute;
  opacity: 0;
  overflow: hidden;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
