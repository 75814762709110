.sidebar-container {
  min-height: 70vh;
  width: 60px;
  background-color: #d5d4d3;
  border-radius: 10px;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  &__open {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-sidebar-arte-final {
  cursor: pointer;
  &__selected {
    cursor: pointer;
    background-color: #ffffff;
    width: 100% !important;
  }
}

.container-arrow-title {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  align-items: center;
  min-width: 285.7px;
}

.container-categories {
  background-color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition:
    max-width 0.5s ease-in-out,
    opacity 0.5s ease-in-out,
    visibility 0.5s;
}

.test-animation {
  max-width: 300px; /* Ajusta este valor según el ancho deseado para tu menú */
  opacity: 1;
  visibility: visible;
}

.card-arte-final {
  box-shadow: 0px 2px 5px 0px rgba(145, 145, 145, 0.6) !important;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.2s ease-in-out;
}

.card-arte-final:hover {
  transform: scale(1.01);
}

.content-arte-not-descargable {
  min-height: 345px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.animation-collapse-categories {
  max-width: 400px; /* Ajusta este valor según el ancho deseado para tu menú */
  opacity: 1;
  visibility: visible;
}
.container-master-user {
  height: 100%;
  padding: 2rem;
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}

.grid-clusters-centers-container {
  width: 100%;
  display: grid;
  // Dos columnas la primera mucho mas pequeña que la segunda
  grid-template-columns: 1fr 5fr;
  gap: 1.5rem;
}

.grid-item-clusters-centers-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.grid-item-clusters-centers-table {
  width: 100%;
  padding: 1rem;
}

.grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columnas de igual ancho */
  gap: 1.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.2rem;
}

.grid-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.container-filter-filter-artes {
  display: flex;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 1rem;
  padding-top: 0.5rem;
}

.container-filter-artes {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1;
  // overflow-y: hidden;
}

.container-filter-menu-master {
  display: flex;
  height: 100%;
}

.container-icons-edit-delete-master {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
}
