.chart-container {
  height: 300px;
}
.rtl {
  .chart {
    direction: ltr;
    canvas {
      direction: rtl;
    }
  }
}
