.not-elements-container {
  background-color: white;
  padding: 2rem;
  width: 80%;
  max-width: 1500px;
  box-shadow: 0px 0px 42px -21px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.aaff-no-compuesto-container {
  margin-left: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.footer-cart-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
