.w-search-40 {
  width: 40%;
}
.w-search-45 {
  width: 45%;
}

.w-search-50 {
  width: 50%;
}

.w-search-55 {
  width: 55%;
}

.w-search-65 {
  width: 65%;
}

.w-search-85 {
  width: 85%;
}

.w-search-100 {
  width: 100%;
}

.font-size-1-resonsive {
  font-weight: 600;
}

.title-master-articulos-filter-category {
  font-weight: bold !important;
  font-size: 1.2rem !important;
}

/* Media query para dispositivos portátiles */
@media (max-width: 1550px) {
  button {
    font-size: 0.7rem !important; /* Tamaño de fuente más pequeño para dispositivos portátiles */
  }

  svg {
    font-size: 17px !important;
  }

  .w-search-55 {
    width: 45% !important;
    height: 100%;
  }

  .w-search-55 input {
    font-size: 0.7rem !important;
  }

  .w-search-65 {
    width: 55% !important;
    height: 100%;
  }

  .w-search-65 {
    font-size: 0.7rem !important;
  }

  .w-search-85 {
    width: 75% !important;
    height: 100%;
  }

  .w-search-85 {
    font-size: 0.7rem !important;
  }

  .menu-item-merchandising-guide {
    font-size: 0.7rem !important;
  }

  .font-size-1-resonsive {
    font-size: 1rem !important;
  }

  .title-master-articulos-filter-category {
    font-size: 1rem !important;
  }

  .font-size-07-responsive {
    font-size: 0.7rem !important; /* Cambia el tamaño de la fuente del input */
  }

  .grid-container {
    grid-template-columns: repeat(3, 1fr) !important; /* 3 columnas de igual ancho */
  }

  .category-row-li {
    font-size: 0.7rem !important;
  }

  .test-animation {
    max-width: 250px !important;
  }

  .options-filter {
    max-width: 250px !important;
  }
}
