.f-right {
  float: right;
}

.d-flex {
  display: flex;
}

.ai-center {
  align-items: center;
}

.g-05 {
  gap: 0.5rem;
}

.fw-bold {
  font-weight: bold;
}

.fs-italic {
  font-style: italic;
}

.fd-column {
  flex-direction: column;
}

.pb-1 {
  padding-bottom: 1rem;
}