.error-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f2f2f2;
    color:red;
  }

  .error-code {
    font-size: 6rem;
    margin: 0;
  }

  .error-text {
    font-size: 1.5rem;
    text-align: center;
    margin-top: 10px;
  }
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f2f2f2;
  color:red;
}

.error-code {
  font-size: 6rem;
  margin: 0;
}

.error-text {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 10px;
}



.image-container {
  height: 30%;
  width: 100%;
 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  animation: flyingBirdAnimation 7s ease infinite; 
}
@keyframes flyingBirdAnimation {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(100%, 0) rotate(0deg);
  }

}











