.aaff-asociated {
  border: 1px solid #ddd; /* Borde sutil */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px 15px; /* Espaciado interno */
  margin: 10px 0; /* Margen para separar elementos */
  background-color: #f9f9f9; /* Color de fondo suave */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra ligera */
  transition:
    transform 0.2s,
    box-shadow 0.2s; /* Animación */
}

.aaff-asociated:hover {
  transform: translateY(-3px); /* Efecto de desplazamiento al pasar el mouse */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Aumenta la sombra */
}

// Vamos a darle a cada aaff un poco mas de tamaño:
.aaff-asociated div {
  font-size: 1.1em;
}
