.gestion-container-categorias-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.f-w-600 {
  font-weight: 600;
}

.gestion-container-categorias {
  display: flex;
  flex-direction: column;
  max-height: 65vh;
  min-height: 50vh;
  overflow-y: auto;
}

.gestion-container-category-subcategories {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.gestion-categoria-item-icons {
  user-select: none;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
}
.gestion-categoria-item-icons__move {
  user-select: none;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  background-color: #a8d5a3 !important;
}

.gestion-categoria-item-icons:hover {
  background-color: #d3d3d3;
  cursor: pointer;
}

.container-status-icons {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.gestion-categoria-item {
  display: flex;
  gap: 0.5rem;
  height: 100%;
  padding: 0.5rem 1rem;
  align-items: center;
}

.gestion-subcategories {
  padding-left: 40px; /* Añadir padding para indicar jerarquía */
  display: flex;
  flex-direction: column;
}

.status-container {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.container-status-category-row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.status-item__disabled {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff010b;
}

.status-item__important {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1976d2;
}

.gestion-container-icons {
  outline: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  padding: 0.5rem 1rem;
}

.gestion-categories-icon {
  outline: none !important;
  cursor: pointer;
}

.gestion-categories-icon:hover {
  transform: scale(1.2); /* Aumenta el tamaño del icono en un 20% */
  transition: transform 0.3s ease-in-out; /* Añade una transición suave */
}

/* -------------------------------------------------------------------------- */
/*                        MODAL PARA CREAR SUBCATEGORIA                       */
/* -------------------------------------------------------------------------- */
.subcategory-name-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.gestion-create-container-subcategories {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
}

.subcategory-row {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  // cursor de arrastrar
  position: relative;
}

.subcategory-row:hover {
  background-color: #d3d3d3;
}
.drag-icon {
  position: absolute;
  left: -15px;
  display: none;
}

.subcategory-row:hover .drag-icon {
  display: inline;
}

.buttons-form-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 1rem;
}

.delete-new-subcategory-icon:hover {
  color: #c62828;
  cursor: pointer;
  transform: scale(1.2); /* Aumenta el tamaño del icono en un 20% */
  transition: transform 0.3s ease-in-out; /* Añade una transición suave */
}

.subcategory-form-container {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.container-aaff-add-masive {
  border: 1px solid #cecece;
  height: 300px;
  overflow: auto;
  display: flex;
  justify-content: center;
}

.row-aaff-add-masive {
  height: 2rem;
  background-color: #f7f7f7;
  font-weight: bold;
  display: flex;
  align-items: center;
  user-select: none;
  gap: 0.5rem;
  padding-left: 1rem;
  width: 100%;
}

.subcontainer-aaff-add-masive {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.description-not-aaff-selected {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-aaff-selected {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.image-not-supported {
  color: #cecece !important;
  width: 60px !important;
  height: 60px !important;
}

.fs-14 {
  font-size: 14px;
}

.p-1 {
  padding: 1rem;
}

.container-buscador {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.container-aaff-selected-noselected {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-arrow-icons {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-color-button-confirm {
  background-color: #3c4145 !important;
}

.container-heading-aaff-selector {
  width: 45%;
}

.subcontainer-heading-aaff-selector {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.descripction-aaff-selector {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
  font-style: italic;
  height: 40px;
}

.category-row-li {
  cursor: pointer;
  padding: 8px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.category-important {
  font-weight: bold;
  color: #ff010b;
}

.category-row-li span {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

.d-flex {
  display: flex;
}

.fd-column {
  flex-direction: column;
}

.ai-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.jc-center {
  justify-content: center;
}

.jc-space-between {
  justify-content: space-between;
}

.pt-1 {
  padding-top: 1rem;
}

.pl-1 {
  padding-left: 1rem !important;
}

.total-artes-category {
  font-weight: bold;
}

.modal-move-category {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 20px;
  justify-items: center;
  min-height: 650px;
  max-height: 650px;
  // height: 43%;
  background-color: rgba(255, 255, 255, 0.95);

  &--flex {
    display: flex;
    flex-direction: column;
  }
}

.container-category-tree {
  height: 420px;
  overflow: auto;
}
