.filters-info-container {
  margin-left: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  flex-direction: row !important;
  background-color: #f2f2f2 !important;
  // background-color: pink !important;
  border: none !important;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
}

.capitulo-tree-header-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.capitulo-tree-header--item-global {
  margin: 0.3rem 0;
}

.capitulo-tree-header--item-text {
  font-weight: bold;
  margin-left: 1rem;
}

.capitulo-tree-item {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__text {
    font-weight: bold;

    &--header {
      font-size: 0.9rem;
      margin-left: 1.5rem;
    }
  }
}

.list-capitulos {
  padding-top: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}

.container-capitulos-project {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
}

.button-new-partida {
  background-color: #2e2e2e;
  color: white;
  font-size: 14px !important;
  border-radius: 4px !important;
  width: 100%;
}

.button-new-partida:hover {
  color: rgb(151, 150, 150) !important;
}

.container-box-newpartida {
  padding: 3rem 1rem;
  background-color: white;
  min-width: 600px;
  max-width: 600px;
  position: absolute;
}

.close-modal-newpartida {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.container-spinner-capitulos-list {
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 2rem;
  margin-top: 1rem;
  justify-content: center;
}

.container-circularprogress {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards-menu {
  display: flex;
  flex-grow: 2;
  padding: 1rem 0.1rem 1rem 1rem;
  gap: 1rem;
  flex-wrap: wrap;
  overflow-y: auto;
  align-content: flex-start;
  justify-content: space-between;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #dfdfdf;
    border-radius: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 1rem;
    transition: all 1s ease;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #6b6b6b;
  }
}

.container-pagination {
  /* position: absolute; */
  margin: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.pagination-cards {
  display: flex;
  justify-content: center;

  & button {
    border-radius: 4px;
  }
}

.border-radius-icon-button {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  height: 40px !important;
}

.button-search-submit {
  height: 100% !important;
  aspect-ratio: 1 / 1 !important;
  background-color: black !important;
  color: white !important;
  min-width: 0 !important;
  // padding: 4px 4px !important;
  border-radius: 4px 0px 0px 4px !important;
}

.icon-btn-focus:focus {
  // outline: none !important;
  // border: 1px solid rgba(210, 210, 210, 0.8);
  border-right: none !important;
}

.input-search-menu {
  border: 1px solid rgba(0, 0, 0, 0.4);
  height: 40px !important;
  width: 100% !important;
  font-size: 14px;
}

.input-search-menu::placeholder {
  font-size: 11.5px;
}

.input-search-menu:focus {
  border: 1px solid #d7d7d7 !important;
  border-right: none !important;
}

// .icon-btn-focus:focus {
//   outline: none !important;
//   border: 1px solid rgba(255, 255, 255, 0.8) !important;
// }

.MuiOutlinedInput-notchedOutline {
  border-color: #d7d7d7 !important;
}

.css-1v867xa-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.animate-card {
  transition: transform 1s ease-in-out;
}

.animate-card:hover {
  transform: scale(1.1); /* Escala el div al 110% */
}

.container-filter-price-partidas {
  width: 200px;
  height: 45px;
  background-color: rgb(255, 255, 255);
  transition: transform 0.3s ease-in-out;
  z-index: 100000;
  position: absolute;
  top: -5px;
  padding: 0 0.7rem;
  transform: scale(1.1);

  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-minmax-filter-price-partidas {
  font-size: 12px !important;
  padding-right: 0.1rem !important;
  padding-left: 0.1rem !important;
  background-color: #f2f2f2 !important;
}

.button-ok-filter-price-partidas {
  border: none;
  background-color: #8c9cbb;
  font-size: 12px;
  padding: 0.2rem 0.5rem;
}

.square-legend {
  width: 10px;
  height: 10px;
  // border-radius: 2px;
  // margin-right: 0.5rem;
  background-color: #8c9cbb;
}

.autocomplete-partida-input {
  & .Mui-focused {
    color: rgba(0, 0, 0, 0.85) !important;
  }
}

.change-param-unit {
  color: #6c757d;
  text-decoration: underline;
  user-select: none;
  width: fit-content;
}

.change-param-unit:hover {
  color: #495057;
  cursor: pointer;
}

.title-param-unit {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin: 0;
}

.param-unit {
  margin: 0;
}

.card-partida-item {
  // width: 200px;
  // height: 100px;
  // background-color: #f6f6f6;
  // color: black !important;
  // // border: 3px solid black;
  // transition: background-color 300ms ease 0ms;
  // font-size: 0.9rem;
  // cursor: pointer;
  // border-radius: 4px;
  // box-shadow: 0px 3px 3px rgba(94, 94, 94, 0.493);
  // padding: 1rem 0.5rem;
  // font-size: 10px;

  &__price {
    background-color: #8c9cbb !important;
    margin: 0;
    padding: 0.1rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #8c9cbb;
    transition: background-color 300ms ease 0ms;
  }

  &__text-price {
    font-size: 12px;
    font-style: italic;
  }
}

.hover-update-version:hover {
  cursor: pointer;
  background-color: #cecece !important;
  transition: ease-in-out 0.3s;
}
