.center-table-cell {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border: none;
  border-bottom: 1px solid #cecece;
}

.input-table-cell {
  width: 100%;
  height: 100%;
  /* border-top: 1px solid #cecece; */
  border-top: none;
  border-bottom: 1px solid #cecece;
  border-left: none;
  border-right: none;
  display: flex;
  justify-content: center;
  /* padding-left: 1rem; */
  /* width: 40px; */

  outline: none;
}

.input-table-cell:focus {
  border: 2px solid rgba(255, 0, 0, 0.8); /* rojo más marcado en todos los bordes */
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 8px;
}

/* TABLA DE ASOCIADOS */
.background-container-table {
  position: relative;
  padding: 1rem;
  /* background: -webkit-linear-gradient(45deg, #f7cac9, #e38e8c); 
  background: linear-gradient(45deg, #f7cac9, #e38e8c);  */
  background-color: whitesmoke;
  height: 500px;
}

.container-table-asociados {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 500px;
  overflow: auto;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.asociate-table table {
  width: 100%;
  max-height: 800px;
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.asociate-table th,
.asociate-table td {
  padding: 15px;
  min-width: 300px;
  background-color: rgba(255, 255, 255, 0.2);
  /* color: #fff; */
}
.asociate-table th {
  text-align: left;
}
.asociate-table thead th {
  background-color: #dddddd; /* Gris claro */
  color: #333; /* Texto oscuro para contraste */
  border: 1px solid #cecece;
}
.asociate-table tbody tr {
  max-height: 200px;
}

.asociate-table tbody tr:hover {
  background-color: rgba(102, 0, 0, 0.2);
}
.asociate-table tbody td {
  position: relative;
  border: 1px solid #cecece;
}
.asociate-table tbody td:hover:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(102, 0, 0, 0.1);
  z-index: -1;
}
.asociate-table tbody td input {
  width: 100%;
  border: none;
  background-color: transparent;
  text-align: center;
  outline: none;
}

/* AsociateTable.css */
.asociate-table td.focused {
  background-color: rgba(102, 0, 0, 0.2);
  /* Agrega otros estilos que desees aplicar al td enfocado */
}

.asociate-table p {
  display: flex;
  align-items: center;
  justify-content: center;
}
