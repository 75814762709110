.form-create-arte-final {
    overflow: auto;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
  }
  
  .form-create-arte-final-div1 {
    display: flex;
    gap: 4rem;
    flex: 1;
  }
  
  .form-create-arte-final-div2 {
    margin-left: 0px;
    width: 100%;
  }
  