@keyframes ldio-yzbaupudwr {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ldio-yzbaupudwr div {
  position: absolute;
  animation: ldio-yzbaupudwr 1s linear infinite;
  width: 120px; /* Ajusta el tamaño deseado */
  height: 120px; /* Ajusta el tamaño deseado */
  top: 15px; /* Ajusta la posición vertical */
  left: 15px; /* Ajusta la posición horizontal */
  border-radius: 50%;
  box-shadow: 0 3px 0 0 #e15b64; /* Ajusta el sombreado */
  transform-origin: 60px 61px; /* Ajusta el punto de origen de la rotación */
}

.loadingio-spinner-eclipse-2saj4c1jge {
  width: 120px; /* Ajusta el tamaño del contenedor */
  height: 120px; /* Ajusta el tamaño del contenedor */
  display: inline-block;
  overflow: hidden;
  background: transparent;
}

.ldio-yzbaupudwr {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.75); /* Ajusta la escala */
  backface-visibility: hidden;
  transform-origin: 0 0; /* Ver nota arriba */
}

.ldio-yzbaupudwr div {
  box-sizing: content-box;
}
