.modal-pdf-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-pdf-container {
  background: white;
  border-radius: 0.2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modal-pdf-buttons-container {
  display: flex;
  justify-content: space-between;
}
