.table {
  color: $primary-color;
}
.table th,
.table td {
  border-color: $separator-color-light !important;
}

.table .thead-light th {
  background-color: $separator-color-light !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $separator-color-light !important;
}

.rtl {
  table.dataTable thead > tr > th.sorting_asc,
  table.dataTable thead > tr > th.sorting_desc,
  table.dataTable thead > tr > th.sorting,
  table.dataTable thead > tr > td.sorting_asc,
  table.dataTable thead > tr > td.sorting_desc,
  table.dataTable thead > tr > td.sorting {
    padding-right: 0;
    padding-left: 30px;
  }

  table.dataTable thead .sorting:before,
  table.dataTable thead .sorting_asc:before,
  table.dataTable thead .sorting_asc_disabled:before,
  table.dataTable thead .sorting_desc:before,
  table.dataTable thead .sorting_desc_disabled:before {
    right: initial;
    left: 2em;
  }

  table.dataTable thead .sorting:after,
  table.dataTable thead .sorting_asc:after,
  table.dataTable thead .sorting_asc_disabled:after,
  table.dataTable thead .sorting_desc:after,
  table.dataTable thead .sorting_desc_disabled:after {
    right: initial;
    left: 1.5em;
  }
}
