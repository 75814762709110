.description {
  max-height: 3em;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description.expanded {
  max-height: none;
}

.expand-icon {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.expand-icon.expanded {
  transform: rotate(180deg);
}


.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper{
  overflow: auto !important;
}