.container-modal-pilotado {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1200px;
  padding: 20px;
  justify-items: center;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 24;
}
