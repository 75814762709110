.pdf-object #document .ndfHFb-c4YZDc-q77wGc{
    display: none !important;
}

.pdf-object #document {
    & .ndfHFb-c4YZDc-Wrql6b-SmKAyb{
        display: none !important;
    }

    & .ndfHFb-c4YZDc-Wrql6b {
        display: none !important;
    }
}