.cursor-default {
  cursor: default !important;
}

.img-name-note {
  display: inline-block;
  overflow: hidden;
  padding: 3px 0;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  max-width: 315px;
  color: #15c;
}

.hidden {
  display: none;
}

.css-1jfsjk7 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.img-size-note {
  color: #444746;
  display: inline-block;
  padding: 3px 0;
}

.options {
  background-color: #f3f3f3;
  border: 1px solid #d8d4d4;
  padding: 1rem;
  margin: 0;
  height: 100%;
  width: 300px;
}

.options-filter {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: fit-content;
}

.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
  overflow-y: hidden !important;
}

.delete-selection-button {
  border: 0.0714286rem solid rgb(236, 237, 238);
  border-radius: 0.214286rem;
  color: rgb(10, 10, 10);
  font-family: 'Nunito', sans-serif;
  cursor: pointer;
  justify-content: center;
  font-weight: 600;
  min-height: 2.85714rem;
  max-width: 100%;
  line-height: 1.2;
  opacity: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  margin-top: 1rem;
  width: 90%;
  /* min-width: 2.85714rem; */
}

.react-datepicker__input-container input {
  cursor: pointer;
  caret-color: transparent;
}

.number-filters-container {
  position: absolute;
  top: -12px;
  right: -10px;
}

.table-order-row {
  background-color: white;
  transition:
    background-color 0.3s,
    transform 0.3s;
}

.table-order-row-hover:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.detail-order-row {
  opacity: 0.8;
}

.detail-order-row:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 1;
  /* font-size: 14.5px !important; */
}

.number-filters {
  background-color: rgb(226, 226, 226);
  border-radius: 1.78571rem;
  color: rgb(10, 10, 10);
  display: inline-block;
  font-size: 0.857143rem;
  font-weight: 600;
  min-width: 1.57143rem;
  padding: 0.214286rem 0.428571rem;
  text-align: center;
}

.font-filters {
  line-height: 1.6;
  font-weight: 800;
  font-family: 'Nunito', sans-serif;
}

.global-search {
  padding: 0.5rem;
  width: 100%;
}

.center-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 0.5rem;
  border: 0.0714286rem solid rgb(224, 224, 224);
  border-radius: 0.214286rem;
  position: relative;
}

.center-filter:hover {
  background-color: #e8eaeb;
  cursor: pointer;
}

.options ul {
  list-style: none;
  margin: 0;
  padding: 6%;
}
.options li {
  list-style: none;
}

.content {
  width: 88%;
  height: 88%;
  padding: 2rem 2rem 4rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.status-filter:hover {
  background-color: #e9e9e9;
  cursor: pointer;
  user-select: none;
}

.burger-menu {
  margin-left: '20%';
  z-index: 999;
  width: 40px;
  height: 40px;
  background-color: #f51414; /* Grey background color */
  border-radius: 50%;
  color: white;
  font-size: 24px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  display: none; /* Initially hidden for large screens */
}

.burger-menu:hover {
  background-color: #333; /* Darker background color on hover */
}

.burger-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px;
  left: 0;
  background-color: #555a5f; /* Grey background color */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  display: none; /* Initially hidden */
}

.burger-menu:hover ul {
  display: block; /* Show menu on hover */
}

.burger-menu ul li {
  padding: 10px;
  text-align: center;
  color: white;
  border-bottom: 1px solid #777;
}

@media screen and (max-width: 768px) {
  .burger-menu {
    display: block; /* Show hamburger menu for small screens */
  }
}
/* Estilos para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .structure {
    flex-direction: column;
  }
  .structure .core {
    margin-left: 10%;
  }
  .structure .options {
    width: 100%;
    height: auto;
  }
  .structure .options ul li {
    justify-content: center;
    text-align: center;
  }
  .structure .core {
    justify-content: center;
  }
}

.title {
  padding: 1rem;
  margin: 0;
  border: 1px solid #adadad;
  display: flex;
  justify-content: space-between;
}

.title-goback {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #6c757d; /* Color gris inicial */
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease; /* Transición suave de color */
}

.title-goback:hover {
  color: #495057; /* Gris más oscuro para el hover */
}

.container-title-pilotados {
  border: 1px solid #adadad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 1rem; */
}

.container-estadisticas {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 3rem;
}

.container-title-estadisticas {
  border: 1px solid #adadad;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 74px;
}

.container-title-estadisticas h2 {
  margin: 0;
  padding: 1rem;
}

.contenido-estadisticas {
  padding: 1rem;
  max-height: 70vh;
}

.custom-tooltip {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-tooltip p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.custom-tooltip .label {
  font-weight: bold;
  color: #555;
}

.custom-tooltip .intro {
  margin-top: 5px;
  color: #888;
}

.menu-estadisticas {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu-estadisticas-item {
  border: 1px solid #dadada;
  border-radius: 8px;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 150px;
}

.menu-estadisticas-description {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Para evitar que el texto se muestre en una sola línea */
}

.menu-estadisticas-item-selected {
  background-color: #fddcdc;
}

.menu-estadisticas-item-description {
  display: grid;
  grid-template-columns: 1fr 25fr;
  column-gap: 0.5rem;
}

.line-space {
  border-top: 1px solid rgb(185, 185, 185);
}

.menu-estadisticas-item-point {
  margin-top: 0.25rem;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #e0001a;
}

.select-container {
  position: relative;
  width: 250px;
  font-family: 'Arial', sans-serif;
}

.select-input {
  width: 100%;
  position: relative;
  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #c62828;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;

    &:focus {
      border-color: #ff7043;
    }
  }
}

.select-dropdown {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #c62828;
  border-radius: 4px;
  z-index: 1;
}

.select-option {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #ffebee;
  }
}

.no-options {
  padding: 10px;
  font-size: 16px;
  color: #999;
  text-align: center;
}

.centered-container {
  height: 350px;
  max-height: 400px;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-50 {
  width: 50%;
}

.w-30 {
  width: 30%;
}

.MuiDataGrid-columnHeaders {
  background-image: linear-gradient(to bottom, #68b7df, #1976d2);
}
.MuiButtonBase-root {
  color: #292929;
}
.MuiDataGrid-columnHeaderTitle {
  color: #0a0a0a;
}
.MuiDataGrid-virtualScroller {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#00000000 10%, #e0001a);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}

.name-children-format {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el texto que no cabe */
  text-overflow: ellipsis; /* Agrega los tres puntos (...) al final del texto truncado */
  max-width: 100px; /* Ancho máximo del contenedor del texto */
  display: inline-block; /* Hace que el contenedor se ajuste al ancho del texto */
}

.open-image-aaff-parent:hover {
  cursor: pointer;
  /* hacerlo mas grande al ponernos encima con animcacion */
  transform: scale(1.2);
  transition: transform 0.5s;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-aaff-pedido-pilotado {
  position: relative;
  width: 80px;
  height: 40px;
}

.loader-aaff-pedido-pilotado div {
  position: absolute;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #535353;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader-aaff-pedido-pilotado div:nth-child(1) {
  left: 8px;
  animation: loader-aaff-pedido-pilotado1 0.6s infinite;
}

.loader-aaff-pedido-pilotado div:nth-child(2) {
  left: 8px;
  animation: loader-aaff-pedido-pilotado2 0.6s infinite;
}

.loader-aaff-pedido-pilotado div:nth-child(3) {
  left: 32px;
  animation: loader-aaff-pedido-pilotado2 0.6s infinite;
}

.loader-aaff-pedido-pilotado div:nth-child(4) {
  left: 56px;
  animation: loader-aaff-pedido-pilotado3 0.6s infinite;
}

@keyframes loader-aaff-pedido-pilotado1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader-aaff-pedido-pilotado3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes loader-aaff-pedido-pilotado2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.format-pedido-pilotado {
  transition: background-color 0.3s ease; /* Define la transición */
}

.format-pedido-pilotado:hover {
  background-color: #e8e8e8; /* Cambia el color de fondo al pasar el ratón */
}

.texto-aaff-pedido-pilotado {
  margin: 0;
  overflow: hidden; /* Oculta el contenido que se desborda del contenedor */
  text-overflow: ellipsis; /* Agrega puntos suspensivos al final del texto truncado */
}

.arrow-button-pedido-pilotado {
  transition: transform 0.3s ease; /* Transición para la rotación */
  transform: rotate(0deg); /* Rotación inicial */
  cursor: pointer; /* Cambia el cursor al pasar el ratón */
  /* Coloca aquí tu definición de backgroundImage */
}

.rotate-arrow-pedido-pilotado {
  transform: rotate(90deg);
}

.list-tienda-pedido-pilotado {
  opacity: 0; /* Inicialmente oculto */
  height: 0; /* Inicialmente con altura 0 */
  overflow: hidden; /* Oculta cualquier contenido que sobresalga */
  transition:
    opacity 0.3s ease,
    height 0.3s ease; /* Transición para mostrar y ocultar */
}

.show-list-tienda-pedido-pilotado {
  opacity: 1;
  height: auto; /* Autoajusta la altura según el contenido */
}

.css-1aquho2-MuiTabs-indicator {
  background-color: #e0001a !important;
}

.pedido-pilotado-margin-separate {
  display: flex;
  align-items: center;
  gap: 3rem;
  width: 100%;
  padding: 1rem;
}

.header-pilotado-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-pilotado-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-pilotado-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.header-pilotado-text {
  font-size: 1rem;
}

.remove-margin-padding {
  margin: 0 !important;
  padding: 0 !important;
}

.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.df {
  display: flex;
}

.df-jcsb {
  display: flex;
  justify-content: space-between;
}

.df-jcc {
  display: flex;
  justify-content: center;
}

.ai-c {
  align-items: center;
}

.h-100 {
  height: 100%;
}

.pt1 {
  padding-top: 1rem !important;
}

.tabs-navigate-admin.active {
  color: #e0001a;
}

.menu-button-selector {
  width: 20%;
  height: 33px;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.modal-create-edit-format {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  min-width: 65vw;
  justify-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 24;
}

.modal-create-edit-format-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.custom-select-provider {
  background-color: #f0f0f0; /* Fondo gris */
  border: none; /* Sin color de borde */
  border-radius: 8px; /* Bordes redondeados */
  padding: 4px 8px; /* Espaciado interno más pequeño */
  font-size: 14px; /* Tamaño de la fuente más pequeño */
  color: #333; /* Color del texto */
  appearance: none; /* Eliminamos la apariencia por defecto */
  -webkit-appearance: none; /* Para navegadores Webkit */
  -moz-appearance: none; /* Para Firefox */
  outline: none; /* Eliminamos el contorno al hacer foco */
  cursor: pointer; /* Cambiamos el cursor */
  min-width: 70%; /* Ancho completo del contenedor */
  text-align: center;
}

.custom-select-provider:focus {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Sombra al hacer foco */
}

.custom-select-provider option {
  background-color: #f0f0f0; /* Fondo gris para las opciones */
  color: #333; /* Color del texto de las opciones */
}

.quantity-display {
  cursor: pointer;
  transition: transform 0.3s;
}

.quantity-display:hover {
  transform: scale(1.2);
}

.not-exists-provider-format {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  align-items: center;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.no-provider-format-text {
  color: #b62929;
}
