.parentDetail {
  /* min-height: calc(100vh - 98px); */
  display: flex;
  justify-content: center;
  /* overflow: auto; */
  padding: 2rem;
  /* margin-top: 98px; */
}

.szh-menu-button {
  width: auto !important;
}

.breadcrumb-hover:hover {
  text-decoration: underline;
  color: black;
}

.comeback {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 12px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.cardDetail {
  padding: 2rem;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  min-height: 80vh;
  width: 85%;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.general-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #d6d6d6;
  margin-bottom: 3rem;
}
.columna {
  display: flex;
  align-items: center;
  height: 55px;
  text-align: start;
  width: 40%;
  padding-left: 1rem;
}

.test-date-picker {
  width : 40% !important;
}

.formDetail {
  display: flex;
  justify-content: center;
  padding: 2%;
  height: 45%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.react-datepicker__input-container {
  margin: 0;
  padding: 0px;
}
.formDetail div {
  width: 40%;
}
.middle {
  width: 50%;
}
.line {
  width: 50%;
  border-bottom: 1px solid #d6d6d6;
}
.label {
  height: auto;
  padding-left: 2px;
  border-left: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.datable {
  height: auto;
  border-right: 1px solid #d6d6d6;
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}
.paddington {
  padding: 8px;
}

.wrapper {
  width: 100%;
  border: 1px solid red;
}

.title-order {
  margin-bottom: 1rem;
}

.formDetail table {
  width: 100%;
}

.normal {
  font-weight: bold;
  font-family: 'roboto';
}
.pedidos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 15px 20% 0px 10%;
  height: 300px;
  overflow-y: auto;
}
.table-order {
  width: 100%;

  overflow-y: auto;
}
.header-table {
  height: 30px;
  background-color: #e0e1e1;
}
.centrado {
  margin-bottom: 10px;

  width: 100%;
}
.mayus {
  text-transform: uppercase;
  font-family: Arial, Helvetica, sans-serif;
}
.table-order tbody tr {
  border-bottom: 1px solid #d6d6d6;
}
.MuiFormLabel-root {
  width: 100%;
}
.react-datepicker__input-container {
  background-color: transparent;
}
.table-order td {
  width: 150px; /* Establecer ancho fijo para cada columna */
  text-align: center;
}

.table-order td:first-child {
  width: 30%;
  text-align: left;
}

.custom-loader {
  position: relative;
  left: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #e0001a 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #e0001a);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}

@keyframes s3 {
  100% {
    transform: rotate(1turn);
  }
}
